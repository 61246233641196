<template>
  <div class="statement-wrap">
    <div class="txt-center">隐私声明</div>
    <p class="tab">深圳市普瑞数安科技有限公司（以下简称“我们”或“普瑞数安”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界通行的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
    <p class="tab">若您使用普瑞数安的网站（www.pisecuriti.com及其子域，以下简称“网站”）、普瑞数安所提供的移动终端应用程序（“普瑞数安APP”或“APP”）的相关服务，及您与普瑞数安进行的非公开互动（例如使用或试用“PICaaS个人信息合规管理云平台”服务）（合称“产品和服务”），请根据本《隐私声明》（“本声明”）了解我们如何收集、使用和共享您的个人信息及您享有何种权利。如我们提供的某项产品或服务有单独的隐私声明或相应的用户服务协议当中存在特殊约定，则该产品的隐私声明将优先适用，该款产品隐私声明和用户服务协议未涵盖的内容，以本声明内容为准。</p>
    <p class="tab">请在使用我们的产品或服务前，仔细阅读并了解本《隐私声明》（下称“本声明/本隐私声明”），特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。除非另有说明，不同意本隐私声明或其更新（我们将及时通知您此类更新）可能会影响您正常使用或继续正常使用我们的产品/服务。如对本声明内容有任何疑问、意见或建议，您可通过 《隐私声明》中所提供的联系方式与我们联系。</p>
    <p class="tab">您了解并同意，只要您在注册账号程序中点击接受本声明并完成账号的注册，或者在本网站的申请试用、了解更多的网页中提交了个人申请，或者实际使用我们的产品和服务，即意味着同意我们按照本《隐私声明》收集、使用、储存和分享您的相关信息。</p>
    <div id="statement0">
      <p class="title">一、我们如何收集和使用您的个人信息</p>
      <p class="tab">个人信息是以电子或其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户等信息，以及不满十四周岁未成年人的个人信息。</p>
      <p>（一） 基本业务功能需要收集和使用您的个人信息</p>
      <p class="tab">我们向您提供的产品和服务的基本业务功能包括：</p>
      <p class="tab">（i）  个人信息合规管理平台相关功能及相关咨询服务；</p>
      <p class="tab">（ii） 为沟通及处理您可能提出的问题及咨询，您与我们之间进行的非公开互动。</p>
      <p class="tab">在以下场景，出于基本业务功能之目的，您需要提供以下类别的个人信息，否则您可能无法享受相应的产品和服务。</p>
      <p class="tab">1．普瑞数安产品和服务使用前的注册服务。当您申请使用普瑞数安的产品和服务时，您至少需要向我们提供您的手机号及密码、工作单位及邮箱。前述信息将用于验证您的身份、匹配和集合您使用普瑞数安个人信息合规产品及服务所进行的一系列操作活动、向您发送有关产品和服务功能的重大变化及更新的通知、与您沟通或为您处理您所提出的咨询及问题。您可以在平台“个人信息”中修改和补充您的头像、部门及职务等信息，这些信息属于您的“账号信息”，您补充的账号信息将有助于我们为您提供更优的使用体验，但如果您不提供这些补充信息，不会影响您使用本产品和服务的基本功能。我们会为您的账号分配特定的ID，该等ID由随机字符串构成，其单独无法被用于查询到您的真实身份。</p>
      <p class="tab">2. 网站互动及客户支持服务。我们通过网站上“联系我们”或“加入我们”页面，以及网站或APP上的“申请试用”或“了解更多”等网页按钮，向您提供产品演示、产品试用、产品资料等客户支持服务，您在使用客户支持服务过程中，我们会收集您与我们之间的通信内容、您所提的要求和意见、您提供的姓名及联系方式、其他您所提供的信息（例如您的工作单位和邮箱），以便帮助您解决此类问题，或向您反馈服务的进度及结果。</p>
      <p>（二） 扩展业务功能需要收集和使用您的个人信息</p>
      <p class="tab">为了完善已有产品或支持我们开发新产品，以及为您提供更为优质的产品及服务，我们会收集和使用以下个人信息。如果您拒绝使用以下功能或拒绝向我们提供以下个人信息，将不会影响到前述的基本业务功能。</p>
      <p class="tab">1. 用户调研活动。我们可能会在网站或APP展示或邀请您参加一些调研活动，就此，我们可能会使用您的账号、手机号向您发送此类通知，您可以在该等短信通知中设置拒绝接收此类推送。当您选择参加此类活动时，根据活动的具体设置，我们可能会向您收集您填写的问卷信息、您的联系方式等，这些信息可以帮助我们对活动结果进行公示，与您取得联系以及向您发送礼品。如果您拒绝提供此类信息，可能导致您无法参与相应的活动或无法收到我们赠送的礼品。</p>
      <p class="tab">2. 内部数据分析与研究，以进行产品和服务性能改进与升级。我们将我们所收集的您使用我们产品和服务的操作日志、您使用网站及APP内客户支持服务向我们进行的问题反馈，将用于我们开展内部数据分析和研究，以支持我们或关联公司的产品或服务性能的改进与提升、新产品的开发。</p>
      <p class="tab">若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及未成年人个人信息的，您需在发布前取得对应未成年人监护人的同意，前述情形下监护人有权通过本声明载明的联系方式联系我们，要求更正或删除涉及未成年人个人信息的内容。</p>
      <p class="tab">若我们将信息用于本声明未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。您充分理解并同意，我们在以下情况下收集、使用您的个人信息而无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：</p>
      <p class="tab">(1) 与国家安全、国防安全有关的；</p>
      <p class="tab">(2) 与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="tab">(3) 与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
      <p class="tab">(4) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p class="tab">(5) 您自行向社会公众公开的个人信息；</p>
      <p class="tab">(6) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p class="tab">(7) 根据与您签订和履行相关协议或其他书面文件所必需的；</p>
      <p class="tab">(8) 用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</p>
      <p class="tab">(9) 为合法的新闻报道所必需的；</p>
      <p class="tab">(10) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
      <p class="tab">(11) 法律法规规定的其他情形。</p>
      <p class="tab">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善我们的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。</p>
    </div>
    <div id="statement1">
      <p class="title">二、我们如何共享、转让、披露个人信息</p>
      <p>1. 共享</p>
      <p class="tab">我们不会与普瑞数安及其关联公司以外的任何企业、组织和个人共享您的个人信息，但以下情况除外：</p>
      <p class="tab">(1) 关联方及服务提供商。我们会根据需要与第三方服务提供商和任何分包商共享您的个人信息，以向您提供我们的产品和服务。如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。我们向第三方服务提供商共享您的个人信息的情形逐项列举请见附件三。</p>
      <p class="tab">(2) 第三方链接和网站。我们的网站或APP可能包含与第三方网站之间的链接。如果您使用第三方服务，请注意这些网站具有自己的隐私声明或隐私权政策，我们对这些政策不承担任何责任。在向这些网站提交任何个人信息之前，请检查其隐私声明。</p>
      <p class="tab">(3) 经过您明确同意的共享。获得您的明确同意后，我们会与第三方共享您的个人信息。</p>
      <p>2. 转让</p>
      <p class="tab">我们不会将您的个人信息转让给任何企业、组织和个人，但以下情况除外：</p>
      <p class="tab">(1) 经过您明确同意的转让。获得您的明确同意后，我们会向第三方转让您的个人信息。</p>
      <p class="tab">(2) 控制权变更引起的转让。由于出售、收购、合并、重组或其他控制权变更，个人信息可能会被转让给第三方。如果我们出售、合并或转让业务的任何部分，则部分销售可能包括您的个人信息。在此种情形下，我们会要求新的持有您个人信息的公司、组织继续受本声明的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</p>
      <p>3. 披露</p>
      <p class="tab">我们仅会在以下情况下，披露您的个人信息：</p>
      <p class="tab">(1) 经过您明确同意的披露。获得您的明确同意后，我们会向第三方披露您的个人信息。</p>
      <p class="tab">(2) 法律要求的披露。我们可能会应政府当局的合法要求（包括为了满足执法要求）披露您的个人信息。为了执行或适用我们的条款及其他协议，或为了保护我们的权利、财产和安全，又或者为了保护他人的权利、财产或安全，我们可能有义务披露或分享您的个人信息以遵守法律义务。这也包括为了防止欺诈而与其他企业和组织交换个人信息。</p>
      <p>4.共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
      <p class="tab">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
      <p class="tab">(1) 与国家安全、国防安全有关的；</p>
      <p class="tab">(2) 与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="tab">(3) 与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
      <p class="tab">(4) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p class="tab">(5) 您自行向社会公众公开的个人信息；</p>
      <p class="tab">(6) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p class="tab">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</p>
    </div>
    <div id="statement2">
      <p class="title">三、我们如何使用Cookie和同类技术</p>
      <p>1. Cookie</p>
      <p class="tab">为了使我们的产品和服务正常运行，有时我们会在您的计算机、移动设备或其他设备上放置名为Cookie的小数据文件，APP内嵌有H5页面。Cookie是网站或移动应用程序在您访问该网站时将其保存在您的计算机、移动设备或其他设备上的小数据文件。它使网站能够记住您在一段时间内的操作和偏好（例如登录名、购物车或其他偏好），因此无论您何时回到站点或从一个页面翻到另一个页面，都不必一直重新输入它们。我们不会将Cookie用于本声明所述目的之外的任何用途。如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。</p>
      <p>2. Cookie同类技术</p>
      <p class="tab">除Cookie外，我们还可能会在网页（APP内嵌有H5页面）使用网站信标、像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
    </div>
    <div id="statement3">
      <p class="title">四、您的权利</p>
      <p>1. 查询、更正和补充您的信息</p>
      <p class="tab">您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：</p>
      <p class="tab">(1) 登录产品服务界面，进入“我的”对个人资料等信息进行查询、更正或补充；</p>
      <p class="tab">(2) 访问网站，进入“联系我们”页面提交您的需求。</p>
      <p>2. 删除您的个人信息</p>
      <p class="tab">在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p class="tab">(1) 如果我们收集和使用个人信息的行为违反法律法规；</p>
      <p class="tab">(2) 如果我们处理个人信息的行为违反了与您的约定；</p>
      <p class="tab">(3) 如果您不再使用我们的产品或服务，或您注销了账户；</p>
      <p class="tab">(4) 如果我们不再为您提供产品或服务；</p>
      <p class="tab">(5) 如果您同意我们收集、使用您的个人信息后又撤回同意。</p>
      <p class="tab">当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</p>
      <p>3. 改变您授权同意的范围</p>
      <p class="tab">每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</p>
      <p>4. 个人信息主体注销账户</p>
      <p class="tab">访问网站“联系我们”反馈您的要求，或通过本声明“联系方式”条款中的信息联系我们，我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15个工作日内完成核查和处理。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</p>
      <p>5. 要求我们解释说明本隐私声明</p>
      <p class="tab">你有权随时要求我们向您解释说明本隐私声明，请根据下方“联系方式”条款中的信息联系我们。</p>
      <p>6. 响应您的上述请求</p>
    </div>
    <div id="statement4">
      <p class="title">五、个人信息的跨境转移</p>
      <p class="tab">我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。如涉及个人信息的跨境转移，我们将提前通知并征求您的意见，并严格履行了法律规定的义务，通过合同等形式确保以不低于本声明规定的程度保护您的个人信息。</p>
    </div>
    <div id="statement5">
      <p class="title">六、 我们如何保护个人信息</p>
      <p class="tab">我们努力为您的个人信息安全提供保障，以防止个人信息的泄露、篡改、丢失、不当使用、未经授权的访问和披露等。当您输入、提交或访问您的个人信息时，我们会采取各种安全措施来维护您的个人信息安全。例如，在可能的情况下，我们将使用加密措施来传输和存储个人信息，并且我们对可能接触到您的信息的工作人员采取最小够用授权原则，严格控制数据的访问流程及批准机制。我们还将通过访问控制和保密承诺进一步限制对此个人信息的访问。</p>
      <p class="tab">请您理解，由于技术水平限制及可能存在的各种恶意行为，有可能因我们可控范围外的因素而不幸发生个人信息安全事件。如安全事件发生，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况告知您，在逐一告知难以实现时，我们将采取合理、有效的方式发布公告，同时，我们还将按照监管部门的要求，主动上报个人信息安全事件的处理情况。</p>
    </div>
    <div id="statement6">
      <p class="title">七、 您的个人信息存储期限</p>
      <p class="tab">为了实现本隐私声明的目的，我们会保留与您或您的设备有关的个人信息。当实现这些目的已不再需要该个人信息时，除非法律要求我们将个人信息保留更长的时间（例如法律法规对网络日志、线上交易记录的保存时限规定），否则我们将删除或以无法识别您身份的形式保存该个人信息。在确定保留期限时，我们会考虑各种标准，例如您要求的或提供给您的产品和服务的类型、您与我们之间的关系的性质和持续时间、可能对我们的产品或服务的重新注册、删除个人信息对于我们向您提供的服务的影响、法律规定的强制保留期限以及法定时效。</p>
    </div>
    <div id="statement7">
      <p class="title">八、声明变更</p>
      <p class="tab">我们可能会适时修订我们的隐私声明。我们会在您登陆及版本更新时以推送通知等的形式发布变更后的隐私声明，对于重大变更（业务功能、出境情况、使用目的等的变更），我们还会提供更为显著的通知（包括但不限于邮件、短信或在推送页面做特别提示等方式）。如您在本隐私声明更新生效后继续使用我们的产品和服务，即表示您已充分阅读、理解并接受更新后的隐私声明并愿意受更新后的隐私声明约束。我们建议您定期查阅本隐私声明以获知最新版本内容。您可以访问网站下方“隐私声明”链接查看本声明。</p>
    </div>
    <div id="statement8">
      <p class="title">九、联系方式</p>
      <p class="tab">您可以通过以下方式与我们联系，我们将在15个工作日内回复：</p>
      <p>1. 如对本声明内容、未成年人个人信息有任何疑问、意见或建议，您可访问网站“联系我们”页面提交您的问题及咨询；</p>
      <p>2. 我们设立了个人信息保护专员，您可以通过以下方式与其联系：</p>
      <p class="tab">邮箱：privacy@pisecuriti.com。</p>
      <p class="tab">联系地址：广东省深圳市南山区粤海街道深圳软件园一期二栋A310</p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.statement-wrap{
  width: 1200px;
  margin: 0 auto;
}
.txt-center{
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
.tab {
  text-indent: 2em;

}
.statement-wrap .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  border-left: none !important;
  padding-left: 0 !important;
}
</style>
